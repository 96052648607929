<template>
  <div v-if="mobileWidth" class="index-mobile" >
    <mobile />
  </div>
  <div v-else-if="padWidth" class="index-pad">
    <pad />
  </div>
  <div v-else class="index-pc">
    <pc />
  </div>
</template>

<script>
import mobile from "./contract-mobile";
import pc from "./contract-pc";
import pad from "./contract-pad";

export default {
  computed: {},
  components: {
    mobile,
    pc,pad
  },
  data() {
    return {
      mobileWidth: window.innerWidth < this.$mobileWidth ,
      padWidth: window.innerWidth >= this.$mobileWidth && window.innerWidth <= this.$padWidth,
      currentWidth: window.innerWidth
    };
  },
  created() {},
  mounted() {
    window.addEventListener('resize',() => this.changeWidth(), false)
  },
  watch: {
    currentWidth(val) {
      this.currentWidth = val
      this.mobileWidth = this.currentWidth <= this.$mobileWidth
      this.padWidth = this.currentWidth > this.$mobileWidth && this.currentWidth <= this.$padWidth;
    }
  },
  methods: {
    changeWidth(){
      this.currentWidth = document.documentElement.clientWidth
    }
  },
  filters: {},
  beforeDestroy () {
    window.removeEventListener('resize', this.changeWidth(), false)
  }
};
</script>
