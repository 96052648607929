<template>
  <div class="main">
    <div class="box">
      <div class="banner ">
        <div class="banner-content  d-flex ">
          <div class="banner-left">
            Find & Post Here. <br/>
            <div class="banner-title d-flex">
              <span>Abundant</span>
              <span class="banner-color">
               <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/ak1AGS7r4VL7nYmejEHzoi.png" alt=""/>
          </span>
              <span>Options!</span>
            </div>
            <div class="sub-title">Discover More, Choose Wisely Here!</div>
          </div>
        </div>

      </div>
      <div class="box-m"></div>
      <div class="main-content-a">
        <div class=" main-box">
          <div class="main-content">
            <div class="left-view">
              <div class="view-item">
                <div class="view-item-title">View By Type</div>
                <div class="view-item-list">
                  <div class="view-item-list-item d-flex" :class="{'view-item-list-item-active':filter.products == 0}" @click="changeSearchType('0')">
                    <div class="view-list-icon"></div>
                    <div class="view-list-word">All</div>
                  </div>
                  <!-- <div class="view-item-list-item d-flex" :class="{'view-item-list-item-active':filter.search_type.indexOf('1') > -1 && filter.search_type.length == 1}" @click="changeSearchType('1')">
                    <div class="view-list-icon"></div>
                    <div class="view-list-word">Animal Nutrition</div>
                  </div> -->
                  <div class="view-item-list-item d-flex" :class="{'view-item-list-item-active':filter.products == 'Animal Nutrition'}" @click="changeSearchType('Animal Nutrition')">
                    <div class="view-list-icon"></div>
                    <div class="view-list-word">Animal Nutrition</div>
                  </div>
                  <div class="view-item-list-item d-flex" :class="{'view-item-list-item-active':filter.products == 'Food'}" @click="changeSearchType('Food')">
                    <div class="view-list-icon"></div>
                    <div class="view-list-word">Food</div>
                  </div>
                  <div class="view-item-list-item d-flex" :class="{'view-item-list-item-active':filter.products == 'Beverage'}" @click="changeSearchType('Beverage')">
                    <div class="view-list-icon"></div>
                    <div class="view-list-word">Beverage</div>
                  </div>
                  <div class="view-item-list-item d-flex" :class="{'view-item-list-item-active':filter.products == 'Dietary Supplements'}" @click="changeSearchType('Dietary Supplements')">
                    <div class="view-list-icon"></div>
                    <div class="view-list-word">Dietary Supplements</div>
                  </div>
                  <div class="view-item-list-item d-flex" :class="{'view-item-list-item-active':filter.products == 'Bakery' }" @click="changeSearchType('Bakery')">
                    <div class="view-list-icon"></div>
                    <div class="view-list-word">Bakery</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-content ">
              <div class="right-content-search search">
                <div class="left">

                  <div class="search-input">
                    <input
                      type="text"
                      placeholder="Search company..."
                      @keydown.enter="getSourcings"
                      v-model="filter.company_name"
                    />
                  </div>
<!--                  <div class="search-input search-select">-->
<!--                    <select-->
<!--                      class="country"-->
<!--                      @change="selectCountry($event)"-->
<!--                      v-model="selectedCountry"-->
<!--                    >-->
<!--                      <option value="0" class="country-item"-->
<!--                      >All Countries</option-->
<!--                      >-->
<!--                      <option-->
<!--                        :value="item.id"-->
<!--                        class="country-item"-->
<!--                        v-for="item in countryList"-->
<!--                        :key="item.id"-->
<!--                      >{{ item.name }}</option-->
<!--                      >-->
<!--                    </select>-->
<!--                  </div>-->

                  <div class=" search-reset">
                    <div
                      class="search-reset-search search-button"
                      title="Search"
                      @click="search"
                    >
                      Search
                    </div>
                  </div>

                  <div class="search-company" v-if="searchCompanyName">
                    <div class="company-name" :title="searchCompanyName">
                      {{ searchCompanyName }}
                    </div>
                    <div class="remove search-button" @click="removeCompany">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 2C10 2 2 10 2 20C2 30 10 38 20 38C30 38 38 30 38 20C38 10 30 2 20 2ZM20 34C12.2 34 6 27.8 6 20C6 12.2 12.2 6 20 6C27.8 6 34 12.2 34 20C34 27.8 27.8 34 20 34ZM12 18V22H28V18H12Z"
                          fill="#F57F45"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-content-line"></div>
              <div class="right-content-c">
<!--                <div class="loading-pic" v-if="loading">-->
<!--                  <img src="@/assets/loading.gif" />-->
<!--                </div>-->
                <div class="empty" v-if="list.length === 0">
                  No Data
                </div>
                <gm-skeleton :showSpin="showSkeleton">
                <div class="right-content-item" v-for="(item, index) in list" v-if="list.length > 0">
                  <div class="right-content-product">
                    <div class="company search-button"
                         title=""> {{ item.company_name || "--" }}</div>
                    <div class="date">{{ item.website || "--" }}</div>
                  </div>
                  <div class="right-content-type font-medium">
                    <div class="type-n" v-for="(productVal,productIndex) in item.product_arr" :key="productIndex">
                      {{ productVal }}
                    </div>
                  </div>
<!--                  <div class="right-content-quantity">-->
<!--                    <div class="quantity-val">{{ item.website || "&#45;&#45;" }}</div>-->
<!--                    <div class="quantity-label">website</div>-->
<!--                  </div>-->
                  <div class="country-and-btns">
                    <div class="right-content-country">
                      <div class="country">
                        <div class="quantity-val">{{ item.address || "" }} {{ item.country  }}</div>
                        <div class="quantity-label">Address</div>
                      </div>

                    </div>
                    <div class="right-content-btn" v-if="!showSkeleton">
                      <div class="action-button" >
                        <!--                      <div class="btn btn-preview">Preview</div>-->
                        <div class="btn btn-processing"  @click="showContacts(item)">Contacts</div>

                      </div>
                    </div>
                  </div>

                </div>
                </gm-skeleton>
                <div class="page" v-if="!showSkeleton">
                  <Page
                    transfer
                    :page-size="limit"
                    :total="total"
                    :current="page"
                    :page-size-opts="[20, 40, 80]"
                    show-elevator
                    show-total
                    show-sizer
                    @on-change="pageChange"
                    @on-page-size-change="pageSizeChange"
                  />
                </div>
<!--                <div class="right-content-item-line"></div>-->
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
    <PopLogin ref="popLogin" @register="register"></PopLogin>
    <PopRegister
      ref="popRegister"
      @successRegister="successRegister"
      @login="loginPopFunc"
    ></PopRegister>
    <PopRegisterEmail
      ref="popRegisterEmail"
      @successRegister="successRegister"
      @login="loginPopFunc"
    ></PopRegisterEmail>
    <PopRegisterSuccess ref="popRegisterSuccess"></PopRegisterSuccess>
    <PopQuotation
      :inquiry="inquiry"
      ref="popQuotation"
      @getSourcings="getSourcings"
      @getAllCompany="getAllCompany"
    ></PopQuotation>
    <PopInquiry
      :inquiry="inquiry"
      ref="popInquiry"
      @getSourcings="getSourcings"
      @getAllCompany="getAllCompanyInquiry"
    ></PopInquiry>
    <EditInquiry
      :isEditInquiry="isEditInquiry"
      ref="editInquiry"
      @getSourcings="getSourcings"
      @inquiryBulkUpload="inquiryBulkUpload()"
    ></EditInquiry>
    <!--    <PopQuotation-->
    <!--      :inquiry="inquiry"-->
    <!--      ref="popInquiry"-->
    <!--      @getSourcings="getSourcings"-->
    <!--    ></PopQuotation>-->
    <editQuotation
      :isEditInquiry="isEditInquiry"
      ref="editQuotation"
      @getSourcings="getSourcings()"
      @bulkUpload="bulkUpload()"
    ></editQuotation>
    <bulkUpload ref="bulkUpload" @getSourcings="getSourcings()"></bulkUpload>
    <inquiryBulkUpload
      ref="inquiryBulkUpload"
      @getSourcings="getSourcings()"
    ></inquiryBulkUpload>
    <quotationImages ref="quotationImages"></quotationImages>
    <PopGuide ref="popGuide"></PopGuide>
    <MnfrCompanyContacts ref="companyContacts" ></MnfrCompanyContacts>
    <PopPackage ref="popPackage"></PopPackage>

    <div class="box-m"></div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { uniq, trim } from "lodash";
import PopLogin from "@/components/popLogin";
import PopInquiry from "@/components/inquiry";
import PopQuotation from "@/components/quotation";
// import PopQuotation from "@/components/quotation";
import EditInquiry from "@/components/editInquiry";
import EditQuotation from "@/components/editQuotation";
import BulkUpload from "@/components/bulkUpload";
import InquiryBulkUpload from "@/components/inquiryBulkUpload";
import moment from "moment";
import quotationImages from "@/components/quotationImages";
import PopGuide from "@/components/guide";
import PopRegister from "@/components/popRegister";
import PopRegisterSuccess from "@/components/popRegisterSuccess";
import PopRegisterEmail from "@/components/popRegisterEmail";
import MnfrCompanyContacts from "../components/mnfrCompanyContactsMobile";
import PopPackage from "@/components/package";

const {
  _querySourcing,
  _querySourcingType,
  _country,
  _querySourcingTypeCate,
  _reviewInquiry,_getContracts
} = api;

export default {
  name: "products",
  computed: {
    ...mapState("user", ["role"])
  },
  watch: {
    role: {
      handler: function() {
        this.getSourcings();
      },
      deep: true
    }
  },
  components: {
    PopLogin,
    PopInquiry,
    EditInquiry,
    // PopQuotation,
    EditQuotation,
    PopQuotation,
    BulkUpload,
    InquiryBulkUpload,
    quotationImages,
    PopGuide,
    PopRegister,
    PopRegisterSuccess,
    PopRegisterEmail,MnfrCompanyContacts,PopPackage
  },
  data() {
    return {
      loginPop: false,
      confLoading: false,
      showInquire: false,
      inquireForm: {
        product_name: "-"
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      industry_usage_grade: [
        "Food Grade",
        "Feed Grade",
        "Industrial Grade",
        "Cosmetic Grade"
      ],
      industry_usage_grade_temp: "",
      compendial_standard: ["USP", "FCC", "JP", "CP"],
      compendial_standard_temp: "",
      curItem: {},
      modType: "card",
      page: 1,
      limit: 20, // must in 5-20
      total: 0,
      list: [
      ],
      originList: [
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
        {product_name:'',company:{company_name:""}},
      ],
      loading: true,
      cateList: [],
      countryList: [],
      filter: {
        company_name: "",
        products: '',
      },
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: "",
      justShowInquire: false,
      activeIndex: 0,
      showInstruction: false,
      allNum: 0,
      progressNum: 0,
      completedNum: 0,
      expiredNum: 0,
      checkValue: 0,
      checkValueArr: [
        { label: "All", checkValue: 0, value: 0 },
        { label: "Active", checkValue: 1, value: 0 },
        { label: "Completed", checkValue: 2, value: 0 },
        { label: "Expired", checkValue: 3, value: 0 }
      ],
      inquiry: {},
      showInquiry: false,
      showAllMenu: false,
      allMenu: "All",
      isEditInquiry: false,
      IsSelectInquiryRole: false,
      selectTypeData: "inquiries",
      selectTypeAlertData: "quotations",
      type: "quotation",
      isCreated: true,
      selectedCountry: 0,
      searchCompanyName: "",
      typeCate: { 1: 0, 2: 0, 3: 0, 4: 0 },
      showSkeleton: true
    };
  },
  mounted() {
    var searchType = this.$route.query.search_type;
    var companyId = this.$route.query.company;
    var companyName = this.$route.query.company_name;
    var hc = this.$route.query.hc;
    if (typeof companyId != "undefined") {
      this.filter.company = companyId;
    }
    if (typeof companyName != "undefined") {
      this.searchCompanyName = companyName;
    }
    if (typeof hc != "undefined") {
      this.filter.hc = hc;
    }
    if (typeof searchType != "undefined") {
      this.filter.search_type = [];
      this.filter.search_type.push(searchType);
    }
    this.getSourcings();
    this.getSourcingType();
    this.getSourcingTypeCate();

  },
  filters: {
    moment(val) {
      return moment
        .utc(val)
        .local()
        .fromNow();
    }
  },
  methods: {
    showContacts(item){
      if(item.hide_num > 0){
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }else{
        this.$refs.popPackage.showPackage = true;
          return;
      }

      }else{
        this.$refs.companyContacts.showContacts = true;
        this.$refs.companyContacts.getContacts(item);
      }


    },
    register() {
      this.$refs.popLogin.showLogin = false;
      this.$refs.popRegisterEmail.showLogin = true;
    },
    loginPopFunc() {
      this.$refs.popRegisterEmail.showLogin = false;
      this.$refs.popLogin.showLogin = true;
    },
    successRegister(token) {
      this.$refs.popRegisterSuccess.showLogin = true;
      this.$refs.popRegisterSuccess.token = token;
    },
    viewGuide() {
      this.$refs.popGuide.showGuide = true;
    },
    viewCompanyImageList(item) {
      if ("" === this.role) {
        this.$refs.popLogin.showLogin = true;
        return;
      }
      this.$refs.quotationImages.showUpload = true;
      this.$refs.quotationImages.companyId = item.company.id;
      this.$refs.quotationImages.companyName = item.company.company_name;
    },
    getAllCompany(val) {
      this.filter = {
        product_name: "",
        user_id: 0,
        involved: 0,
        search_type: ["1", "2", "3", "4"],
        country: 0,
        company: 0,
        new: 0
      };
      if (val[1].indexOf("***") > -1) {
        this.filter.hc = "y";
      } else {
        this.filter.hc = 0;
      }
      this.filter.company = val[0];
      this.searchCompanyName = val[1];
      this.getSourcings();
      this.getSourcingType();
      this.$refs.popQuotation.showInquiry = false;
    },
    getAllCompanyInquiry(val) {
      this.filter = {
        product_name: "",
        user_id: 0,
        involved: 0,
        search_type: ["1", "2", "3", "4"],
        country: 0,
        company: 0,
        new: 0
      };
      if (val[1].indexOf("***") > -1) {
        this.filter.hc = "y";
      } else {
        this.filter.hc = 0;
      }
      this.filter.company = val[0];
      this.searchCompanyName = val[1];
      this.getSourcings();
      this.getSourcingType();
      this.$refs.popInquiry.showInquiry = false;
    },
    search() {
      this.page = 1;
      this.getSourcings();
      this.getSourcingType();
      this.getSourcingTypeCate();
    },
    reset() {
      this.filter = {
        company_name: "",
        products: '',
      };
      this.page = 1;
      this.allMenu = "All";
      this.selectedCountry = 0;
      this.searchCompanyName = "";
      this.getSourcings();
      this.getSourcingType();
      this.getSourcingTypeCate();
    },
    removeCompany() {
      this.searchCompanyName = "";
      this.filter.company = 0;
      this.filter.hc = 0;
      this.getSourcings();
      this.getSourcingType();
    },
    searchCompany(item) {
      this.page = 1;
      this.searchCompanyName = item.company.company_name;
      var companyId = item.company.id;
      this.filter = {
        product_name: "",
        user_id: 0,
        involved: 0,
        search_type: ["1", "2", "3", "4"],
        country: 0,
        company: 0,
        new: 0
      };
      this.filter.hc = "n";
      if (item.hidden_company) {
        this.filter.hc = "y";
      }
      const str = Math.random()
        .toString(36)
        .substr(2, 10);
      var data = str + companyId;
      var hashId = window.btoa(data);
      this.filter.company = hashId;
      this.getSourcings();
      this.getSourcingType();
    },
    selectCountry(event) {
      let val = event.target.value;
      // this.selectedCountry = val;
      this.filter.country = val;
      // this.getSourcings();
    },
    changeSearchType(val) {
      this.page = 1;
      this.filter.products = val;
      this.getSourcings();
      this.getSourcingType();
      this.getSourcingTypeCate();
    },
    bulkUpload() {
      this.$refs.bulkUpload.showInquiry = true;
      this.$refs.editQuotation.showInquiry = false;
    },
    inquiryBulkUpload() {
      this.$refs.inquiryBulkUpload.showInquiry = true;
      this.$refs.editInquiry.showInquiry = false;
    },
    selectType() {
      if (this.selectTypeData === "inquiries") {
        this.selectTypeData = "quotations";
        this.selectTypeAlertData = "inquiries";
        this.type = "quotation";
      } else {
        this.selectTypeData = "inquiries";
        this.selectTypeAlertData = "quotations";
        this.type = "inquiry";
      }
      this.getSourcings();
      this.getSourcingType();
    },
    selectInquiryRole(v) {
      if (!this.isCreated) {
        if (v === "buyer") {
          this.$refs.editInquiry.showInquiry = true;
          this.selectTypeData = "inquiries";
          this.selectTypeAlertData = "quotations";
          this.type = "inquiry";
        } else {
          this.$refs.editQuotation.showInquiry = true;
          this.selectTypeData = "quotations";
          this.selectTypeAlertData = "inquiries";
          this.type = "quotation";
        }
      } else {
        if (v === "buyer") {
          this.selectTypeData = "quotations";
          this.selectTypeAlertData = "inquiries";
          this.type = "quotation";
        } else {
          this.selectTypeData = "inquiries";
          this.selectTypeAlertData = "quotations";
          this.type = "inquiry";
        }
      }
      this.getSourcings();
      this.getSourcingType();
    },
    changeAllMenu(val) {
      if(this.role === '' && "All" != val){
        this.$refs.popLogin.showLogin = true;
        return;
      }
      this.allMenu = val;
      this.showAllMenu = false;
      this.page = 1;

      if ("All" == val) {
        this.filter.user_id = 0;
        this.filter.involved = 0;
        this.filter.new = 0;
      }
      if ("My Products" == val) {
        this.filter.user_id = 1;
        this.filter.involved = 0;
        this.filter.new = 0;
      }
      if ("Contacted" == val) {
        this.filter.user_id = 0;
        this.filter.involved = 1;
        this.filter.new = 0;
      }
      if ("New" == val) {
        this.filter.user_id = 0;
        this.filter.new = 1;
        this.filter.involved = 0;
      }
      if ("Viewed" == val) {
        this.filter.user_id = 0;
        this.filter.new = 2;
        this.filter.involved = 0;
      }
      this.getSourcings();
      this.getSourcingType();
    },
    showMenu() {
      this.showAllMenu = !this.showAllMenu;
    },
    view(item) {
      if ("" === this.role) {
        this.$refs.popLogin.showLogin = true;
        return;
      }
      if (item.type === 1) {
        this.$refs.popInquiry.showInquiry = true;
        this.$refs.popInquiry.inquiry = item;
      } else {
        this.$refs.popQuotation.showInquiry = true;
        this.$refs.popQuotation.inquiry = item;
      }
      _reviewInquiry({ id: item.id });
    },
    changeCheckValue(val) {
      this.page = 1;
      this.checkValue = val;
      this.filter.status = val;
      this.getSourcings();
      this.getSourcingTypeCate();
    },
    login() {
      if (this.role === "") {
        this.$refs.popLogin.showLogin = true;
        return;
      } else {
        this.$refs.selectInquiryRole.showRole = true;
        this.isCreated = false;
        return;
      }
    },
    jumpTo(path,login = false) {
      if (this.role === "" && login) {
        this.$refs.popLogin.showLogin = true;
        return;
      }
      this.$router.push(path);
    },
    showInstructionFun() {
      this.$refs.popGuide.showGuide = true;
      // this.showInstruction = !this.showInstruction;
    },
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.product_name = "";
      this.getSourcings();
      this.getSourcingType();
    },

    handleSearch() {
      this.page = 1;
      this.getSourcings();
      this.getSourcingType();
    },

    pageChange(page) {
      this.page = page;
      this.getSourcings();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getSourcings();
    },
    getSourcings() {
      this.loading = true;
      this.list = this.originList;
      // if (this.type === "quotation") {
      //   this.filter.search_type = this.filter.search_type.toString();
      // } else {
      //   this.filter.search_type = "0";
      // }
      _getContracts({
        page: this.page,
        limit: this.limit,
        ...this.filter,
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
          // if (this.filter.products === "0") {
          //   this.filter.products = ["2", "3", "4"];
          // } else {
          //   this.filter.products = this.filter.search_type.split(",");
          // }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.showSkeleton = false;
        });
    },

    getSourcingType() {

    },
    getSourcingTypeCate() {

    },
    addCheckBox(key) {
      const value = trim(this[`${key}_temp`]);
      if (value) {
        const arr = uniq([...this[key], value]);
        this[key] = arr;
        this.industry_usage_grade_temp = "";
        this.compendial_standard_temp = "";
      }
    },
    cancel() {
      this.loading = false;
      this.handleReset("form");
      this.industry_usage_grade_temp = "";
      this.compendial_standard_temp = "";
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
</script>
<style  src="@/assets/css/normalize.min.css" scoped>

</style>
<style  src="@/assets/css/open-props.min.css">

</style>
<style >
  .left-btn ul {
    padding: 0;
    display: inline-grid;
    grid-auto-flow: column;
    list-style-type: none;
    grid-template-columns: auto repeat(2, calc((var(--active, 0) * 180px) + 20px));
    transition: grid-template-columns 0.45s var(--ease-elastic-1);
    /*--active: 1;*/
  }

  .left-btn main {
    width: 66ch;
  }

  .left-btn li {
    display: grid;
    justify-content: end;
    z-index: calc(var(--count) - var(--index));
    /*align-items: center;*/
  }

  .left-btn nav {
    position: relative;
  }

  .left-btn nav > button {
    position: absolute;
    left: 0;
    background: red;
    z-index: var(--count);
  }

  .left-btn a {
    background: hsl(0 0% 60% / 0.18);
    display: inline-block;
    padding: var(--size-2) var(--size-4);
    border-radius: var(--radius-5);
    margin: 0;
    color: var(--gray-0);
    font-size: 18px;
    font-weight: 600;
    backdrop-filter: blur(40px);
    transition: color 0.2s, background 0.2s;
  }
  .left-btn .li-index-1{
    cursor: auto;
  }

  .left-btn a:visited {
    color: var(--gray-0);
  }

  .left-btn svg {
    height: 24px;
    aspect-ratio: 1;
    fill: currentColor;
  }

  .left-btn span {
    display: flex;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: 16px 1fr;
    gap: 0.25rem;
    height: 50px;
    width: 150px;
    justify-content: center;
  }

  .left-btn li:is(
  :nth-of-type(2),
  :nth-of-type(3),
  :nth-of-type(4)
) span {
    opacity: var(--active, 0);
  }

  .left-btn li:is(
  :nth-of-type(2),
  :nth-of-type(3)
  /*:nth-of-type(4)*/
) a:is(:hover, :focus) {
    background: var(--gray-0);
    color: var(--gray-12);
  }

  .left-btn a:focus-visible {
    outline-color: transparent;
  }

  .left-btn ul:is(:focus-within, :hover) {
    --active: 1;
  }
</style>
<style lang="less" scoped>
  .d-flex{
    display: flex;
  }
.sc-width {
  /*background: #ffffff;*/
}
.box {
  margin: 50px auto 0 auto;
  background: #f2f2f2;
  .main-box {
    background: #fff;
    border-radius: 15px;
  }
}
.box-m {
  height: 24px;
  background: #fff;
}
.main-title-box {
  /*display: flex;*/
  align-items: center;
  justify-content: space-between;
  /*background: #fff;*/
  padding: 40px;
  /*border-bottom: 1px solid #ccc;*/
  margin: 0 auto;
  /*padding-top: 65px;*/
  padding-left: 0;
  padding-right: 0;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .main-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .exhibitor-sourcing {
    display: flex;
    justify-content: space-between;
    .sell-product {
      background: #f57f45;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      height: 35px;
    }
    .buy-product {
      border: 1px solid #f57f45;
      color: #f57f45;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 20px;
      height: 35px;
    }
  }
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .my-sourcing {
    /*width: 200px;*/
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary */

    color: #f57f45;
  }
  .add-sourcing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    background: #f57f45;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }

  .first {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 22px;
    .user {
    }
    .view {
      color: #f57f45;
      margin-left: 5px;
    }
  }
  .instruction {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    .step-item {
      .step-top {
        display: flex;
        .icon {
        }
        .word {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;

          /* Gray 2 */

          color: #ffffff;
          margin-left: 12px;
          position: relative;
          bottom: 2px;
        }
      }
      .pic {
        margin-top: 10px;
      }
      .first-pic {
        margin-top: 40px;
      }
    }
    .step-line {
    }
  }
  .hide-instruction {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  /*padding: 20px;*/
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 235px;
  }
}
.main {
  background: #f2f2f2;
  .box-top {
    height: 27px;
  }
}
.cards {
  .table-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: 36px;
  }
}
.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    margin-top: 206px;
    .empty {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cards {
      min-height: 300px;
      position: relative;

      .list-table {
        list-style: none;
        li {
          margin-bottom: 10px;
          background-color: #fff;
          border: 1px solid #f2f2f2;
          box-shadow: 0 0 4px #f8f8f8;
          .header {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .wrap1 {
              min-width: 80%;
              cursor: pointer;
              text-align: left;
              .title {
                font-size: 14px;
              }
            }
            .wrap2 {
              text-align: right;
              .status {
                display: inline-block;
                background-color: #ddd;
                padding: 2px 8px;
                margin-bottom: 4px;
                &.pend {
                  background-color: #ddd;
                  color: #333;
                }
                &.acc {
                  background-color: #d5ffd2;
                  color: #418d3a;
                }
                &.rej {
                  background-color: #ffddd9;
                  color: #c04c3a;
                }
              }
              .time {
                color: #888;
              }
            }
          }
          .content {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #f2f2f2;
            .con {
              padding: 10px;
              .tit {
                color: #666;
                padding-bottom: 6px;
              }
              .tip {
                font-size: 12px;
              }
              .doc {
                color: #ff6600;
                a {
                  color: #ff6600;
                }
              }
            }
            .c1 {
              cursor: pointer;
            }
            .c1,
            .c2,
            .c3 {
              flex: 0 0 20%;
            }
            .c4 {
              flex: 0 0 40%;
            }
          }
        }
        .footer {
          padding: 10px;
          text-align: right;
          .numb {
            text-align: left;
            padding: 4px 10px;
            display: inline-block;
            background-color: #ffe7d6;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }

      .ivu-poptip-body {
        h4 {
          font-size: 12px;
          text-align: left;
          color: #444;
          padding: 4px 0;
        }
        .tags {
          flex-wrap: wrap;
          display: flex;
          p {
            text-align: left;
            color: #999;
            flex: 0 0 50%;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .list-table {
    .title {
      display: flex;
      height: 42px;
      background: #f2f2f2;
      border-radius: 5px;
      justify-content: space-between;
      font-size: 18px;

      font-weight: 500;
      color: #757575;
      line-height: 19px;
      .item {
        font-size: 16px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 40px;
      }
      .product {
        width: 400px;
      }

      .attendee {
        width: 300px;
      }
      .size {
        width: 300px;
      }
      .status-col {
        width: 150px;
        padding-left: 30px !important;
      }
      .date {
        width: 200px;
      }
      .document {
        width: 300px;
      }
    }
    .content {
      /*display: flex;*/
      .item {
        min-height: 70px;
        /*padding:0 30px;*/
        .top {
          display: flex;
          min-height: 70px;
          justify-content: space-between;
          border-bottom: 1px solid #ececec;
          div {
            display: flex;
            justify-content: left;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          /*padding-top: 30px;*/
          /*div {*/
          /*  font-size: 16px;*/
          /*  */
          /*  font-weight: 500;*/
          /*  color: #323538;*/
          /*  line-height: 19px;*/
          /*  padding-left: 20px;*/
          /*}*/
          .size {
            padding-left: 40px;
          }
          .status-col {
            width: 150px;
            padding-left: 30px;
            margin: auto 0;
          }
          .product {
            width: 400px;
            .main-name {
            }
            .by-name {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .attendee {
            width: 300px;
            padding-left: 40px;
          }
          .size {
            width: 300px;
            padding-left: 40px;
          }
          .date {
            width: 200px;
            padding-left: 40px;
            .action{
              display: none;
              padding: 8px 15px;
              border-radius: 10px;
              font-size: 16px;
            }
            .sell-action{
              background: #FF4835;
              color: #fff;
              /*display: flex;*/
              /*justify-content: center;*/
              /*align-items: center;*/
            }
            .buy-action{
              background: #3E1DCE;
              color: #fff;
              /*display: flex;*/
              /*justify-content: center;*/
              /*align-items: center;*/
            }
            .view-action{
              border: 1px solid #3E1DCE;
              color: #3E1DCE;
            }
            svg{
              width: 26px;
              height: 26px;
            }
          }
          .document {
            width: 300px;
          }
        }
        .divided {
          /*width: 1440px;*/
          height: 1px;
          background: #cccccc;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
      .active {
        background: #f2f0f9;
      }
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.browse {
  cursor: pointer;
  color: #ef842d;
  padding-right: 20px;
}
.search-top-menu {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
  }
  padding: 40px 0 10px 40px;
  .item {
    margin-right: 25px;
    .value {
      font-size: 18px;
    }
    .value-active {
      color: #f57f45;
      font-weight: 600;
    }
    .line {
      height: 2px;
      background-color: #f57f45;
      position: relative;
      top: 11px;
    }
  }
  .select-role {
    display: flex;
    margin-right: 60px;
    .alert {
      position: relative;
      width: 0;
      height: 0;
      right: 215px;
      .left {
        width: 192px;
        height: 72px;

        /* Grays/Casper/CA-700 */

        background: #334155;
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 1;
        color: #fff;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 10px;
        position: relative;
        bottom: 25px;
      }
      .right {
        position: relative;
        left: 190px;
        bottom: 60px;
      }
    }
    .icon {
      margin-left: 10px;
    }
    .select-quotation {
      /*display: none;*/
      position: relative;
      width: 0;
      top: 25px;
      right: 115px;
      background: #fff;
      height: 0;
      .item {
        margin-bottom: 5px;
        height: 35px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 5px;
        width: 130px;
        background: #334155;
        color: #fff;
      }
    }
  }
}
.border {
  border-top: 1px solid #ececec;
}
.status {
  /*width: 90px;*/
  height: 30px;
  border-radius: 5px;
  display: flex;
  /*justify-content: center !important;*/
  color: #fff;
  font-size: 16px;
  align-items: center;
  font-weight: 600;
  overflow: initial !important;
  span {
    margin-left: 4px;
  }
}
.status-date {
  font-size: 13px;
  color: #6e6893;
}
.status-progress {
  color: #11828f;
}
.status-expired {
  color: #c0399f;
}
.status-completed {
  color: #925acf;
}
.search {
  display: flex;
  padding-left: 7px;
  border: none;
  justify-content: space-between;
  .left {
    display: flex;
    /*position: relative;*/
    /*left: -30px;*/
    .search-icon {
      position: relative;
      left: 35px;
      top: 10px;
      img{
        width: 18px;
        height: 18px;
      }
    }
    .search-input {
      input {
        background: #f2f2f2;
        outline: none;
        border-radius: 5px;
        border: none;
        width: 240px;
        height: 30px;
        padding-left: 5px;
      }
      input:focus {
        border: 1px solid #f57f45;
        background: #fff;
      }
      select {
        outline: none;
        border-radius: 10px;
        border: 1px solid #ececec;
        width: 190px;
        height: 40px;
        background: #f2f2f2;
        .country-item {
          text-align: center;
        }
        option:hover {
          background: yellow;
        }
      }
    }
    .search-select {
      width: 210px;
    }
    .search-company {
      position: relative;
      left: 13px;
      top: 8px;
      .company-name {
        color: #f57f45;
        font-weight: 700;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .remove {
        position: relative;
        float: right;
        bottom: 32px;
        left: 18px;
      }
    }
  }
  .all {
    margin: 9px 0 0 0;
    .all-search {
      display: flex;
      padding-right: 40px;
      align-items: center;
      .name {
        padding-right: 8px;
        font-size: 17px;
      }
    }
    .all-menu {
      background: #fff;
      z-index: 999;
      position: absolute;
      width: 160px;
      margin-top: 10px;
      padding: 10px;
      .item {
        margin-bottom: 5px;
        height: 35px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 5px;
      }
      .item:hover {
        background: #f2f0f9;
      }
    }
  }
}
.loading-pic {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  img {
    height: 70px;
    width: 70px;
    border-radius: 10px;
  }
  text-align: center;
}
.div-flex {
  display: flex;
}
.type {
  padding-right: 40px;
  position: relative;
  bottom: 7px;
  .value {
    padding-top: 7px;
    .value-item {
      margin-right: 15px;
    }
  }
}
.item .top .company-name {
  color: #f57f45;
  font-weight: 700;
}
.search-reset {
  display: flex;
  height: 31px;
  .search-reset-search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    background: #f57f45;
    border-radius: 5px;
    margin: 0 10px 0 5px;
    color: #fff;
  }
  .search-reset-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    color: #000;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }
}
.banner {
  height: 180px;
  background: #120D21;
  /*padding-top: 20px;*/
  .banner-content{
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    padding: 0 10px;
  }
  .banner-left{
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    span.banner-color{
      margin: 4px 5px 5px 5px;
      img{
        width: 95px;

      }
    }
    .sub-title{
      color: #C7C7C7;
      font-size: 15px;
      margin-top: 15px;
      font-weight: 500;
      display: flex;
    }
  }
  .banner-right{
    margin-right:130px ;
    img{
      width: 430px;
      height:250px;
      border-radius:10px
    }
    
  }
  .title {
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    margin: 35px auto 15px auto;
    /*margin-top: 30px;*/
    span {
      color: #f9c141;
      font-size: 36px;
      font-weight: 700;
    }
  }
  .sub-title {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin: 10px auto 0px auto;
    /*width: 920px;*/
    span {
      color: #2f7ddf;
      font-weight: 600;
      font-size: 20px;
    }
  }
}
.guide {
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin-top: 20px;
  font-weight: 600;
  span {
    font-size: 13px;
    cursor: pointer;
    color: #ee7b22;
  }
}
</style>
<style lang="less" scoped>
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
.list-table {
  width: 100%;
}
.main-box {
  margin: 0 auto;
}
.main-title-box-p {
  margin: 0 auto;
  background: #3b3951;
}
.top-image {
  width: 50px;
  .contact-img {
    width: 30px;
    height: 30px;
    position: relative;
    left: 15px;
  }
}
.title-type {
  width: 50px;
}
.font-medium {
  .type-div {
    padding: 8px 10px;
    border-radius: 10px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .type-1 {
    background: rgba(0, 176, 80, 0.15);
  }
  .type-2 {

    background: rgba(0, 176, 240, 0.15);
  }
  .type-3 {
    background: rgba(255, 153, 51, 0.15);
  }
  .type-4 {
    background: rgba(205, 85, 85, 0.15);
  }
}
  .item-icon .icon{
    svg{
      width: 24px;
      height: 24px;
    }
  }
  .active{
    .action{
      display: block !important;
    }
  }
  .main-content-a{
    background: #fff;
    padding: 0 10px;
  }
  .left-view{
    /*width: 220px;*/
    border-right: 1px solid #eaecf1;
    /*margin-right: 50px;*/
    .view-item{
      .view-item-title{
        font-size: 14px;
        color: #272727;
        font-weight: 600;
        /*margin-top: 10px;*/
      }
      .view-item-list{
        .view-item-list-item{
          width: 100%;
          margin: 9px 0;
          cursor: pointer;
          padding: 4px 8px;
          .view-list-word{
            color: #8F8F8F;
            font-size: 13px;
            font-weight: 500;

          }
        }
        .view-item-list-item-active{
          background: #efeeea;
          border-radius: 5px;
          .view-list-word{
            color: #777;
            font-weight: 600;
          }
        }
      }
    }
    .view-item-line{
      background: #eaecf1;
      height: 1px;
      width: 130px;
      margin: 15px 0;
    }
  }
  .right-content{
    /*width: 1350px;*/
    .right-content-product{
      /*width: 450px;*/
      padding-right: 20px;
      .product{
        font-size: 15px;
        font-weight: 600;
        color: #676767;
      }
      .company{
        font-size: 13px;
        font-weight: 700;
        /*color: #949494;*/
        /*margin: 15px auto 0 auto;*/
      }
      .date{
        font-size: 13px;
        color: #676767;
      }
    }
    .right-content-type{
      /*width: 240px;*/
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 10px;


      .type-n{
        font-size: 12px;
        color: #676767;
        font-weight: 500;
        /*width: 120px;*/
        background: #efefef;
        border-radius: 4px;
        padding: 3px 6px;
        margin-bottom: 5px;
        width: fit-content;
        margin-right: 3px;
      }
    }
    .right-content-quantity{
      width: 200px;
      /*margin-left: 30px;*/
      padding-left: 50px;
      .quantity-label{
        font-size: 13px;
        color: #676767;
      }
      .quantity-val{
        font-size: 17px;
        color: #676767;
        font-weight: 600;
      }
    }
    .right-content-country{
      /*width: 220px;*/
      margin-left: 5px;
      .country{
        .quantity-label{
          font-size: 12px;
          color: #676767;
        }
        .quantity-val{
          font-size: 14px;
          color: #676767;
          font-weight: 500;
        }
      }

    }
    .right-content-status{
      width: 150px;
      .country{
        .quantity-label{
          font-size: 13px;
          color: #676767;
        }
        .quantity-val{
          font-size: 15px;
          color: #676767;
          font-weight: 600;
        }
      }
    }
    .right-content-item{
      border-bottom: 1px solid #eaecf1;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .right-content-item-line{
      background: #eaecf1;
      height: 1px;
      margin: 15px auto 15px auto;
    }
    .right-content-line{
      background: #eaecf1;
      height: 1px;
      margin: 10px auto 0 auto;
      width: 100%;
    }
  }

  .cate{
    /*width: 650px;*/
    padding-top: 10px;
    padding-left: 0;
    padding-bottom: 0;
    justify-content: space-between;
    .cate-left{
      justify-content: start;
    }
    .cate-right{
      cursor: pointer;
      align-items: center;
      margin-right: 20px;
      &:hover{
        border-radius: 15px;
        padding: 2px 4px 2px 4px;
        background: #f57f45;
        color: #fff;
        box-shadow: 0 0 0 6px #f57f45;
        transition: background-color .12s cubic-bezier(.8,0,.16,1),box-shadow .12s cubic-bezier(.8,0,.16,1),color .12s cubic-bezier(.8,0,.16,1);
        svg{
          stroke: #fff;

        }
      }
      .cate-right-word{
        font-size: 12px;
        margin-right: 3px;
      }
      .cate-right-icon{
        svg{
          &:hover{
            stroke: #fff;
          }

        }

      }
    }
    .cate-item{
      margin-right: 30px;
    }
  }
  .cate-line{
    background: #eaecf1;
    height: 1px;
    margin: 10px auto 20px auto;
  }

  .right-content-btn{
    /*width: 150px;*/
    margin-top: 7px;
    .action-button{
      /*margin-top: 40px;*/
    }
    .action-button{
      display: flex;
      // flex-direction: column;
      gap: 1rem;
      align-items: baseline;
      justify-content: center;
      background: #fff;
      .btn{

      }
      .btn-preview{
          width: 100px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #3E1DCE;
          border: 1px solid #3E1DCE;
          border-radius: 10px;
          padding: 9px 12px;
          font-size: 13px;
          cursor: pointer;
          font-weight: 500;
          &:hover{
            /*opacity: 0.8;*/
            background: #3E1DCE;
            color: #fff;
          }
        }
        .btn-processing{
          width: 75px;
          height: 29px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #3E1DCE;
          border: 1px solid #3E1DCE;
          border-radius: 10px;
          /*padding: 9px 12px;*/
          font-size: 12px;
          cursor: pointer;
          font-weight: 500;
          &:hover{
            /*opacity: 0.8;*/
            background: #3E1DCE;
            color: #fff;
          }
        }
      
    }
  }
  .country-and-btns{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

</style>
